import Image from 'next/legacy/image';
import Link from 'next/link';

function Logo({
  logoURL,
  link,
  width,
  height,
  beta,
  onClick,
}: {
  logoURL: string;
  link?: string;
  width: number;
  height: number;
  beta?: boolean;
  onClick?: () => void;
}) {
  return (
    <div className="is-flex is-flex-direction-row is-align-items-flex-end">
      {link ? (
        <Link href={link} onClick={onClick}>
          <div style={{ width, height }}>
            <Image src={logoURL} alt="Logo" width={width} height={height} />
          </div>
        </Link>
      ) : (
        <div style={{ width, height }} onClick={onClick} role="none">
          <Image src={logoURL} alt="Logo" width={width} height={height} />
        </div>
      )}

      {beta && (
        <div
          style={{
            marginBottom: '3em',
            marginLeft: '-1.5em',
            zIndex: 2,
          }}
        >
          <span
            className="tag is-primary has-text-weight-semibold"
            style={{ borderRadius: '99px', fontSize: '0.6em' }}
          >
            BETA
          </span>
        </div>
      )}
    </div>
  );
}

Logo.defaultProps = {
  beta: false,
  onClick: () => {},
};

export default Logo;
