import Link from 'next/link';
import { useRouter } from 'next/router';
import { Text, cn } from '@typedream/ui';
import Logo from '@/sharedComponents/Logo';
import LoginSignupForm from '@/components/LoginSignupForm';
import { NEW_LOGO_WITH_TEXT_URL, PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from '@/constants';
import animationStyles from '@/styles/Animation.module.css';

function LoginSection() {
  const router = useRouter();

  return (
    <div
      className={cn(
        'flex flex-col justify-center items-start w-full max-w-[414px] py-10 bg-white px-4 md:px-0',
        animationStyles.fadeInFromBottom
      )}
    >
      <Logo logoURL={NEW_LOGO_WITH_TEXT_URL} link="/" width={156} height={40} />
      <Text size="display-sm" weight="semibold" className="mt-7 mb-3" variant="main" as="p">
        Log in
      </Text>
      <Text className="mb-9" size="sm">
        By logging in, you agree to our
        <a href={TERMS_OF_SERVICE_LINK} target="_blank" rel="noreferrer">
          <Text as="span" variant="link" size="sm" weight="semibold">
            {' '}
            Terms of Service{' '}
          </Text>
        </a>
        and
        <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
          <Text as="span" variant="link" size="sm" weight="semibold">
            {' '}
            Privacy Policy{' '}
          </Text>
        </a>
      </Text>

      <LoginSignupForm login redirect />

      <Text className="my-3" size="sm" weight="medium">
        Don&apos;t have an account yet?{' '}
        <Link
          href={{
            pathname: '/signup',
            query: router.query,
          }}
        >
          Sign up for free
        </Link>
      </Text>
    </div>
  );
}

export default LoginSection;
